import { Group, Rect, Text } from 'react-konva'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getMapCoord } from '@/components/shared/map/polygon/Polygon'
import { useMapStore } from '@/stores/mapStore'
import { useGlobalStore } from '@/stores/globalStore'
import {
  defaultMapPointImage,
  getImageLink
} from '@/components/shared/map/point/Point'
import { API } from '@/api'
import useImage from 'use-image'
import MaternityImg from '@/images/maternity.svg'
import BtripImg from '@/images/btrip.svg'
import SickImg from '@/images/sick.svg'
import WorkImg from '@/images/work.svg'

const getStatusImage = (status: string) => {
  if (status == 'btrip' || status == 'vacation') return BtripImg
  if (status == 'maternity') return MaternityImg
  if (status == 'sick') return SickImg
  if (status == 'work') return WorkImg
  return null
}

const useMapAvatar = (bookingData, node) => {
  const imgSrc = bookingData
    ? API.user.getAvatar({ userId: Number(bookingData.user_id) })
    : getImageLink(node?.icon) || getImageLink(defaultMapPointImage)
  const [pointImg] = useImage(imgSrc, 'anonymous')

  return { image: pointImg }
}

const BasicPoint = (props) => {
  const {
    colors,
    point,
    options,
    nodes,
    bookings,
    hasDepartment,
    bookingData,
    allBookings,
    available,
    node,
    userStatus,
    userInOffice
  } = props

  const layers = useMapStore((state) => state.layers)
  const setSeat = useGlobalStore((state) => state.setSeat)
  const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
  const [width, height] = useMapStore((state) => state.size)
  const setTooltip = useMapStore((state) => state.setTooltip)
  const statusImageURL = useMemo(() => getStatusImage(userStatus), [userStatus])
  const [statusImage, status] = useImage(statusImageURL, 'use-credentials')
  const textRef = useRef<any>(null)
  const groupRef = useRef<any>(null)
  const [coord, setCoord] = useState({ x: 0, y: 0, pX: 0 })

  const { id, x, y, name, type_uid, plugin_data } = point
  const { labelSize, fontSize, color, borderWidth, wrapText } = options

  const isEmployeeVisible = layers['employees']
  const { image } = useMapAvatar(isEmployeeVisible ? bookingData : null, node)
  const text = isEmployeeVisible ? bookingData?.display || name : name
  const displayText = wrapText ? text.split(' ').join('\n') : text

  const onSelect = useCallback(() => {
    setSeat(Number(point.id))
    if (bookingData) {
      setSeatEmployee(Number(bookingData.user_id))
    } else {
      setSeatEmployee(null)
    }
  }, [setSeat, setSeatEmployee, bookingData, point])

  const onMouseEnterHandler = React.useCallback((e) => {
    const container = e.target.getStage()?.container()

    if (container) {
      container.style.cursor = 'pointer'
    }
  }, [])

  const onMouseLeaveHandler = React.useCallback((e) => {
    const container = e.target.getStage()?.container()

    if (container) {
      container.style.cursor = 'default'
    }
  }, [])

  const onGroupMouseEnterHandler = React.useCallback(
    (e) => {
      setTooltip(name)
    },
    [setTooltip, name]
  )

  const onGroupMouseLeaveHandler = React.useCallback(
    (e) => {
      setTooltip(null)
    },
    [setTooltip]
  )

  const getColor = useMemo(() => {
    if (!hasDepartment || !Object.keys(colors).length) return node?.border
    const color = colors[hasDepartment]

    if (!hasDepartment || !color) return node?.border
    return color
  }, [point, node, hasDepartment, colors])

  useEffect(() => {
    if (width > 0 && height > 0) {
      const pointX =
        -textRef.current?.textWidth / 2 + (width * labelSize) / 2 ||
        (width * labelSize) / 2
      setCoord({
        x: getMapCoord(width, x),
        y: getMapCoord(height, y),
        pX: Number(pointX)
      })
    }
  }, [textRef.current, image, width, height, displayText])

  useEffect(() => {
    if (!groupRef.current) return
    groupRef.current.on('mouseenter', onGroupMouseEnterHandler)
    groupRef.current.on('mouseleave', onGroupMouseLeaveHandler)
  }, [groupRef.current])

  return (
    <Group
      x={coord.x}
      y={coord.y}
      offsetX={(width * labelSize) / 2}
      offsetY={(width * labelSize) / 2}
      onClick={onSelect}
      onTap={onSelect}
      listening={true}
    >
      <Group
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        opacity={available ? 1 : 0.3}
        id={'point' + point.id}
        ref={groupRef}
      >
        <Rect
          width={width * labelSize}
          height={width * labelSize}
          stroke={getColor}
          strokeWidth={fontSize * borderWidth}
          fill={node?.background}
          cornerRadius={node?.radius}
          shadowForStrokeEnabled={false}
          perfectDrawEnabled={false}
          listening={available}
        />
        {image && (
          <Rect
            width={width * labelSize}
            height={width * labelSize}
            cornerRadius={node?.radius}
            fillPatternImage={image}
            fillPatternScaleX={(width * labelSize) / image?.width}
            fillPatternScaleY={(width * labelSize) / image?.width}
            shadowForStrokeEnabled={false}
            perfectDrawEnabled={false}
          />
        )}
        {userStatus && statusImage && (
          <Group y={0} x={-width * labelSize * 0.15}>
            <Rect
              width={width * labelSize * 0.4}
              height={width * labelSize * 0.4}
              cornerRadius={9999}
              fill="#fff"
              shadowForStrokeEnabled={false}
              perfectDrawEnabled={false}
              listening={true}
            />
            <Rect
              width={width * labelSize * 0.4}
              height={width * labelSize * 0.4}
              cornerRadius={node?.radius}
              fillPatternImage={statusImage}
              fillPatternScaleX={(width * labelSize * 0.4) / statusImage?.width}
              fillPatternScaleY={(width * labelSize * 0.4) / statusImage?.width}
              shadowForStrokeEnabled={false}
              perfectDrawEnabled={false}
            />
          </Group>
        )}
      </Group>
      <Group y={width * 1.2 * labelSize} x={coord.pX - 2.5}>
        <Rect
          width={textRef.current?.width() + 5}
          height={textRef.current?.height() + 5}
          fill={'rgba(255,255,255,0.9)'}
          cornerRadius={4}
          shadowForStrokeEnabled={false}
          perfectDrawEnabled={false}
          listening={true}
        />
        <Text
          ref={textRef}
          text={displayText}
          align="center"
          verticalAlign="middle"
          fontSize={fontSize}
          fill={color}
          x={2.5}
          y={2.5}
          listening={true}
          perfectDrawEnabled={false}
        />
        {bookingData && (
          <Rect
            width={width * labelSize * 0.25}
            height={width * labelSize * 0.25}
            x={textRef.current?.width() + 2}
            y={-2}
            stroke="#fff"
            fill={
              userInOffice && (userInOffice == 1 || userInOffice == true)
                ? '#00ff00'
                : '#ff0000'
            }
            strokeWidth={width * labelSize * 0.03}
            cornerRadius={4}
            shadowForStrokeEnabled={false}
            perfectDrawEnabled={false}
            listening={true}
          />
        )}
      </Group>
    </Group>
  )
}

export default BasicPoint
