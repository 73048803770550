import { useQuery } from 'react-query'
import { useToast } from '@/components/shared/toast/useToast'
import { useProject } from '@/hooks/useProject'
import { UserService } from '../services/user.service'

type UseUsersProps = {
  page: number
  perPage?: number
  name?: string
  sort?: string
  direction?: 0 | 1
  filter?: any
}

export const useUsers = ({
  page,
  name,
  perPage,
  sort,
  direction,
  filter
}: UseUsersProps) => {
  const { workspaceId, projectId } = useProject()
  const { enqueueToast } = useToast()

  const { data, isLoading, refetch } = useQuery(
    [
      'users_list',
      workspaceId,
      projectId,
      page,
      perPage,
      name,
      sort,
      direction,
      filter
    ],
    () =>
      UserService.getUsersList({
        workspaceId,
        projectId,
        page: page - 1,
        perPage,
        name,
        sort,
        direction,
        filter
      }),
    {
      enabled: !!workspaceId && !!projectId && Number(page) >= 0,
      select: ({ data }) => ({ items: data.items, total: data.total }),
      keepPreviousData: true,
      onError: () => {
        enqueueToast(
          {
            title: 'Ошибка!',
            message: 'Не удалось загрузить список пользователей'
          },
          { variant: 'error' }
        )
      }
    }
  )

  return { data, isLoading, refetch }
}
