import { RuntimeFieldData, UserData } from '@/api/user'
import { logger } from 'react-query/types/react/logger'
import { getUserPropStatusValue } from '@/components/SeatBar'

type UserItem = {
  id: number
  name: string
  department: string
  email: string
  phone: string
}

export type UserExtra = {
  label: string
  accessor: string
  uid: string
  alias: string
  type: string
}

export const makeEmployeeData = (
  data: UserData[] | undefined,
  columns: UserExtra[] | undefined
) => {
  if (!data || !columns) return []

  return data.map((user) => {
    const userData = [
      {
        label: 'id',
        value: Number(user.id),
        type: 'id',
        alias: 'id'
      },
      {
        label: 'display',
        value: user.display,
        type: 'display',
        alias: 'display'
      }
    ]

    columns.forEach((col) => {
      if (col.label.startsWith('Штат')) {
        userData.push({
          label: col.label,
          value: user[col.uid] == 1 ? 'Штатный' : 'Внештатный' || '',
          type: col.type,
          alias: col.alias
        })
        return
      }
      if (col.alias.toLowerCase().startsWith('status')) {
        userData.push({
          label: col.label,
          value: getUserPropStatusValue(user[col.uid]),
          type: col.type,
          alias: col.alias
        })
        return
      }

      if (col.alias.toLowerCase().startsWith('inoffice')) {
        userData.push({
          label: col.label,
          value: user[col.uid] == '1' ? 'Да' : 'Нет',
          type: col.type,
          alias: col.alias
        })
        return
      }
      userData.push({
        label: col.label,
        value: user[col.uid] || '',
        type: col.type,
        alias: col.alias
      })
    })

    return { id: Number(user.id), data: userData }
  })
}

export const makeEmployeeMobileData = (
  data: UserData[] | undefined,
  columns: UserExtra[] | undefined
) => {
  if (!data || !columns) return []

  return data.map((user) => {
    const userData: any = {
      id: Number(user.id),
      display: user.display,
      fields: []
    }

    columns.forEach((col) => {
      if (user[col.uid]) {
        if (col.label.startsWith('Штат')) {
          userData.fields.push({
            name: col.label,
            value: user[col.uid] == 1 ? 'Штатный' : 'Внештатный' || ''
          })
          return
        }
        userData.fields.push({
          name: col.label,
          value: user[col.uid]
        })
      }
    })

    return userData
  })
}

export const makeEmployeeStateReportData = (
  data: UserData[] | undefined,
  columns: UserExtra[] | undefined
) => {
  if (!data || !columns) return []

  return data.map((user) => {
    let display = user.display
    let state = ''
    let department = ''

    columns.forEach((col) => {
      if (col.label.startsWith('Штат')) {
        state = user[col.uid] == 1 ? 'Штатный' : 'Внештатный' || ''
      }
      if (col.alias.includes('#department')) {
        department = user[col.uid]
      }
    })

    return { id: Number(user.id), display, state, department }
  })
}
