import React from 'react'
import styled from 'styled-components'
import { Place } from '@/components/Elements/ElementsListItem'
import { translate } from '@/i18n'

const StateEmployeeMobileItem = ({ item }) => {
  return (
    <ItemWrapper>
      <Name>{item.state}</Name>
      <PropsWrapper>
        <Property>
          <PropName>{translate('type')}</PropName>
          <PropValue>{item.state}</PropValue>
        </Property>
        <Property>
          <PropName>Департамент</PropName>
          <PropValue>{item.department}</PropValue>
        </Property>
      </PropsWrapper>
    </ItemWrapper>
  )
}

export default StateEmployeeMobileItem

const ItemWrapper = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 15px 10px;
  color: #000;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

const PropsWrapper = styled.div`
  margin: 6px 0 12px 0;
`

const PropName = styled.div`
  max-width: 120px;
  width: 100%;
`
const PropValue = styled.div`
  width: 100%;
  font-weight: 700;
`

const Property = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
`

const Name = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
`
