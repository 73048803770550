import React from 'react'
import { Grid } from '@/components/Grid'
import { NodeElement } from '@/api/project'
import { useProjectStore } from '@/stores/projectStore'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import useResponsive from '@/hooks/useResponsive'
import { translate } from '@/i18n'
import StateEmployeeMobileItem from '@/components/layout/Sidebar/Reports/reports/state-report/StateEmployeeMobileItem'

type ElementsListItemProps = {
  item: {
    id: number
    display: string
    department: string
    state: string
  }
}

const StateEmployeeItem: React.FC<ElementsListItemProps> = ({ item }) => {
  const { isDesktop } = useResponsive()

  if (!isDesktop) return <StateEmployeeMobileItem item={item} />

  return (
    <Grid.Row $cols="2fr 1fr 1fr">
      <Grid.Item>{item.display}</Grid.Item>
      <Grid.Item>{item.department}</Grid.Item>
      <Grid.Item>{item.state}</Grid.Item>
    </Grid.Row>
  )
}

export default StateEmployeeItem

export const Place = ({ item }) => {
  const nodes = useProjectStore((state) => state.nodes)
  const place = nodes.find((tree) => tree.id == item.parent)

  return <div>{place ? place.name : translate('not-specified')}</div>
}
