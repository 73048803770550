import Close from '@/components/Close'
import Grid from '@/components/Grid'
import Modal from '@/components/Modal/Modal'
import media from '@/ui/media'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTree } from '@/api/hooks/useTree'
import { useLayerInfo } from '@/api/hooks/useLayerInfo'
import { useMetadata } from '@/api/hooks/useMetadata'
import Toolbar from '@/components/Toolbar/Toolbar'
import { SelectInput } from '@/ui/components/Field/Select'
import useSorting from '@/hooks/useSorting'
import { makeCols, TableCell } from '@/components/Employee/Employees'
import Pagination from '@/ui/components/Pagination/Pagination'
import useResponsive from '@/hooks/useResponsive'
import { translate } from '@/i18n'
import { useIntl } from 'react-intl'
import { ReportButton } from '@/components/layout/Sidebar/Reports/ReportView'
import { useUsers } from '@/api/hooks/useUsers'
import { useUsersListerFields } from '@/api/hooks/useUsersListerFields'
import StateEmployeeItem from '@/components/layout/Sidebar/Reports/reports/state-report/StateEmployeeItem'
import { makeEmployeeStateReportData } from '@/components/Employee/makeEmployeeData'
import { printPDF } from '@/utils/func/print'
import { CSVLink } from 'react-csv'
import { formatToReport } from '@/utils/helpers/dates.helpers'

type ReportModalProps = {
  report: string | null
  item: {
    name: string
    key: string
  }
  onClose: () => void
}

const StateEmployeesReport: React.FC<ReportModalProps> = ({
  report,
  item,
  onClose
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const intl = useIntl()
  const [type, setType] = useState<string>('')
  const { data: extra } = useUsersListerFields()
  const columns = makeCols(extra?.fields)
  const filterName = useMemo(
    () =>
      columns.find(
        (col) => col.alias == 'fulltime' || col.label.startsWith('Штат')
      )?.uid || '',
    [columns]
  )
  const { sort, direction, handleSort } = useSorting()
  const { data } = useUsers({
    page: currentPage,
    perPage: 20,
    sort,
    filter: {
      [filterName]: type
    },
    direction
  })

  const { data: reportData } = useUsers({
    page: currentPage,
    perPage: 1000,
    sort,
    filter: {
      [filterName]: type
    },
    direction
  })

  const users = makeEmployeeStateReportData(data?.items, columns)
  const usersReport = makeEmployeeStateReportData(reportData?.items, columns)

  const { isDesktop } = useResponsive()
  const handleTypeChange = (e) => setType(e?.target?.value)

  const cols = ['display', 'department', 'type']

  const translations = {
    display: 'ФИО',
    department: 'Департамент',
    type: 'Тип'
  }

  const formatData = useMemo(() => {
    return data
      ? usersReport.map((item) => {
          return {
            display: item.display,
            department: item.department,
            type: item.state
          }
        })
      : []
  }, [users])

  const pdfCols = useMemo(
    () => cols.map((col) => ({ header: translations[col], dataKey: col })),
    [cols]
  )

  return (
    <Modal isOpen={!!report} onClose={onClose}>
      <Header>
        <Title>
          {translate('report-template', {
            name: intl.formatMessage({ id: item.key })
          })}
        </Title>
        <ExportWrapper>
          <ReportButton
            onClick={() =>
              printPDF({
                name: intl.formatMessage({ id: item.key }),
                columns: pdfCols,
                body: formatData
              })
            }
          >
            PDF
          </ReportButton>
          <CSVLink
            data={formatData || ''}
            headers={cols.map((col) => ({
              label: translations[col],
              key: col
            }))}
            separator={';'}
            filename={`${intl.formatMessage({ id: item.key })} ${formatToReport(
              new Date()
            )}`}
          >
            <ReportButton>CSV</ReportButton>
          </CSVLink>
          <Close color="#000" onClick={onClose} />
        </ExportWrapper>
      </Header>

      <Toolbar>
        <Toolbar.Item xs={12} md={4}>
          <Toolbar.BlackLabel>Тип</Toolbar.BlackLabel>
          <SelectInput
            $fullWidth
            placeholder="Выберите тип сотрудников"
            value={type}
            onChange={handleTypeChange}
          >
            <option value="">Все</option>
            <option value="1">Штатные</option>
            <option value="null">Внештатные</option>
          </SelectInput>
        </Toolbar.Item>
      </Toolbar>

      <Grid>
        {isDesktop && (
          <Grid.RowHeader $cols="2fr 1fr 1fr">
            <TableCell
              onClick={handleSort.bind(null, 'display')}
              $active={sort === 'display'}
              $direction={direction}
            >
              ФИО
            </TableCell>
            <TableCell>Департамент</TableCell>
            <TableCell>Тип</TableCell>
          </Grid.RowHeader>
        )}

        {users?.map((spot) => (
          <StateEmployeeItem key={spot.id} item={spot} />
        ))}
      </Grid>

      <Pagination
        inverse
        currentPage={currentPage}
        total={data?.total || 0}
        handlePageChange={setCurrentPage}
      />
    </Modal>
  )
}

export default StateEmployeesReport

const ReportItem = ({ item }) => {
  const { layers } = useTree()
  const { data } = useLayerInfo(item.id)
  const { metadata } = useMetadata()

  const currentNode = useMemo(() => {
    if (data && metadata && metadata.layers) {
      return metadata.layers[data.info.type_uid]
    }
    return null
  }, [data, metadata])

  const parent = layers?.nodes?.find((p) => p.id == item.parent)?.name || ''
  const props = linkLayerInfoProperties(
    currentNode?.plugin_data,
    data?.info?.plugin_data
  )

  const properties = {
    name: props.find((prop) => prop.name == 'Компания')?.value || '',
    area: props.find((prop) => prop.name == 'Площадь')?.value || '',
    count: props.find((prop) => prop.name == 'Кол-во мест')?.value || ''
  }

  return (
    <Grid.Row $cols="repeat(3, 1fr)">
      <Grid.Item>{item.name}</Grid.Item>
      <Grid.Item>{parent}</Grid.Item>
      <Grid.Item>{properties.name}</Grid.Item>
    </Grid.Row>
  )
}

const NotFound = styled.div`
  width: 100%;
  text-align: center;
  padding: 12px 0;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lg`
      flex-direction: column;
      align-items: flex-start;
  `}
`

const ExportWrapper = styled.div`
  display: flex;
  align-items: center;

  * {
    &:not(:last-child) {
      margin-right: 0.8rem;
    }
  }

  ${media.lg`
      margin-top: 20px;
  `}
`

const Title = styled.div`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #000000;
`
