import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import bigChair from '@/images/big-chair.png'
import icBooking from '@/images/ic-booking.png'
import icChair from '@/images/ic-chair.png'
import icEmp from '@/images/ic-emp.png'

import { API } from '@/api'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useUser } from '@/api/hooks/useUser'
import { useUserCardFields } from '@/api/hooks/useUserCardFields'
import DraggableControl from '@/components/draggable/draggable-control/DraggableControl'
import EmailIcon from '@/components/ui/icon/EmailIcon'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { translate } from '@/i18n'
import { useGlobalStore } from '@/stores/globalStore'
import { useProjectStore } from '@/stores/projectStore'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import Avatar from '@/ui/components/Avatar'
import { formatPhoneNumber } from '@/utils/helpers/phone.helpers'
import { isEmail } from '@/utils/helpers/string.helpers'
import { easePoly } from 'd3-ease'
import Draggable from 'react-draggable'
import { ErrorBoundary } from 'react-error-boundary'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { animated, useTransition } from 'react-spring'
import { makeUserCardData } from './EmployeeBar'
import Grid from './Grid'
import CloseIcon from './icons/CloseIcon'
import { maintenanceDialog } from './Maintenance/MaintenanceModal'
import { bookingDialog } from './shared/booking/form/BookingModal'
import {
  defaultMapPointImage,
  getImageLink,
  isBookableForMe
} from './shared/map/point/Point'
import { PrimaryButton } from './ui/button/PrimaryButton'
import { useProject } from '@/hooks/useProject'

const linkProperties = (node, meta) => {
  if (!node || !meta) return []
  const keys = Object.keys(node)

  const data = keys
    .map((key) => meta[key])
    .filter((v) => v)
    .find((item) => item.fields)?.fields
  const nodeData = keys
    .map((key) => node[key])
    .filter((v) => v)
    .find((item) => item.fields)?.fields

  if (!data || !nodeData) return []
  const props = data
    .map((item) => {
      const info = nodeData.find((n) => n.field_id === item.id)

      if (!info || typeof info.value === 'object') return null
      return {
        name: String(item.name),
        type: String(info.type_id),
        value: String(info.value)
      }
    })
    .filter((v) => v)

  return props
}

export const formatBooleanValue = (value: string) => {
  if (value == 'false') return 'Нет'
  if (value == 'true') return 'Да'
  return value
}

export const getUserPropStatusValue = (value: string) => {
  if (value == 'work') return 'Работа'
  if (value == 'vacation') return 'Отпуск'
  if (value == 'btrip') return 'Коммандировка'
  if (value == 'maternity') return 'Декрет'
  if (value == 'sick') return 'Больничный'
  return ''
}

export const formatPropValue = (property: {
  alias: string
  name: string
  value: any
}) => {
  if (property.name.startsWith('Штат')) {
    return property.value == '1' ? 'Штатный' : 'Внештатный'
  }

  if (property.alias.toLowerCase().startsWith('status')) {
    return getUserPropStatusValue(property.value)
  }

  if (property.alias.toLowerCase().startsWith('inoffice')) {
    if (property.value == '1') return 'Да'
    return 'Нет'
  }

  return property.value
}

const SeatBar = () => {
  const [active, setActive] = useState(false)
  const { workspaceId, projectId } = useProject()
  const [section, setSection] = useState<'user' | 'seat' | null>(null)

  const categories = useUserStore((state) => state.categories)
  const seat = useGlobalStore((state) => state.seat)
  const setSeat = useGlobalStore((state) => state.setSeat)
  const seatEmployee = useGlobalStore((state) => state.seatEmployee)
  const role = useUserStore((state) => state.role)
  const me = useUserStore((state) => state.user)
  const fullMode = useProjectStore((state) => state.fullMode)

  const { metadata } = useMetadata()

  const { data, refetch } = useQuery(
    'seat_info',
    () =>
      API.projects.getNodeInfo({
        workspaceId: Number(workspaceId),
        projectId: Number(projectId),
        nodeId: Number(seat)
      }),
    { enabled: !!(workspaceId && projectId && seat), refetchOnMount: false }
  )

  const { data: userData } = useUser(seatEmployee)
  const { data: extra } = useUserCardFields()

  const user = makeUserCardData(userData?.user, extra?.fields)

  useEffect(() => {
    if (!seat) {
      setTimeout(() => {
        setSection(null)
      }, 300)
    } else {
      refetch()
    }

    if (seat) {
      setSection(null)
    }
  }, [seat])

  const nodes = metadata?.rawNodes || {}
  const seatInfo =
    Object.keys(nodes).find((key) => key === data?.node_info?.type_uid) || ''
  const meta = metadata?.rawNodes[seatInfo]

  // @ts-ignore
  const props = linkProperties(data?.node_info?.plugin_data, meta?.plugin_data)
  const imgSrc = getImageLink(meta?.icon) || getImageLink(defaultMapPointImage)
  const avaliable = isBookableForMe(data?.node_info?.plugin_data, categories)

  const transitions = useTransition(seat, {
    from: { opacity: 0, translateY: 100, translateX: '-50%' },
    enter: { opacity: 1, translateY: 0, translateX: '-50%' },
    leave: { opacity: 0, translateY: 100, translateX: '-50%' },
    config: {
      duration: 300,
      easing: easePoly.exponent(2)
    },
    onChange: () => {
      setActive(false)
    },
    onRest: () => {
      if (seat) {
        setActive(true)
      }
    }
  })

  const toggleSection = (name) => {
    if (section && section !== name) {
      setSection(name)
    } else if (section === null) {
      setSection(name)
    } else {
      setSection(null)
    }
  }

  const availableForBooking = avaliable && fullMode

  const refBody = useRef(null)

  useOnClickOutside(refBody, () => {
    if (active) {
      setSeat(null)
      setActive(false)
    }
  })

  return transitions((style, item) =>
    item ? (
      <Draggable handle=".handle">
        <Wrapper style={style} ref={refBody}>
          {data && data.node_info && (
            <>
              <DraggableControl className="handle" />
              <Title>{data?.node_info.name}</Title>
              <SectionsWrapper>
                {availableForBooking && (
                  <Section
                    onClick={() => bookingDialog({ nodeId: data.node_info.id })}
                  >
                    <img src={icBooking} />
                    <SectionTitle $expanded={section === null}>
                      {translate('book')}
                    </SectionTitle>
                  </Section>
                )}
                <Section
                  $active={section === 'seat'}
                  onClick={toggleSection.bind(null, 'seat')}
                >
                  <img src={icChair} />
                  <SectionTitle $expanded={section === null}>
                    {translate('about-object')}
                  </SectionTitle>
                </Section>
                {seatEmployee && (
                  <Section
                    $active={section === 'user'}
                    onClick={toggleSection.bind(null, 'user')}
                  >
                    <img src={icEmp} />
                    <SectionTitle $expanded={section === null}>
                      {translate('about-employee')}
                    </SectionTitle>
                  </Section>
                )}
                <Section onClick={() => setSeat(null)}>
                  <CloseIcon color="#000" />
                  <SectionTitle $expanded={section === null}>
                    {translate('close')}
                  </SectionTitle>
                </Section>
              </SectionsWrapper>
              <Container $expanded={section !== null}>
                {section === 'seat' && data && data.node_info && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <img src={imgSrc || bigChair} />
                    </div>

                    <Table>
                      <tbody>
                        <tr>
                          <td>{translate('type')}</td>
                          <td>{meta?.name}</td>
                        </tr>

                        <tr>
                          <td>{translate('location')}</td>
                          <td>
                            {[...data?.node_info?.parent_name]
                              .filter((v) => v)
                              .join('. ')}
                          </td>
                        </tr>

                        <ErrorBoundary fallback={<></>}>
                          <>
                            {props
                              .filter((v) => v.value)
                              .map((property) => (
                                <ErrorBoundary fallback={<></>}>
                                  <tr key={property.name}>
                                    <td>{property.name}</td>
                                    <td>{formatProperty(property)}</td>
                                  </tr>
                                </ErrorBoundary>
                              ))}
                          </>
                        </ErrorBoundary>
                      </tbody>
                    </Table>
                    {/*<Grid.Row $cols="2fr">*/}
                    {/*  <PrimaryButton*/}
                    {/*    $fullWidth*/}
                    {/*    type="button"*/}
                    {/*    onClick={() => {*/}
                    {/*      maintenanceDialog({*/}
                    {/*        seat: data.node_info.name,*/}
                    {/*        employee: me?.display || ''*/}
                    {/*      })*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    {translate('service')}*/}
                    {/*  </PrimaryButton>*/}
                    {/*</Grid.Row>*/}
                  </>
                )}
                {section === 'user' && userData && userData.user && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <Avatar
                        src={API.user.getAvatar({ userId: userData.user.id })}
                        size="large"
                      />
                      <Name>{userData.user.display}</Name>
                    </div>

                    <Table>
                      {role === RolesEnum.Admin &&
                        user
                          .filter((v) => v.uid.includes('booking_category'))
                          .map((property) => (
                            <tr key={property.uid}>
                              <td>{property.name}</td>
                              <td>
                                {isEmail(property.value) ? (
                                  <a href={`mailto:${property.value}`}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginRight: 4,
                                          display: 'flex',
                                          alignItems: 'center',
                                          flexShrink: 0
                                        }}
                                      >
                                        <EmailIcon />
                                      </div>
                                      <div> {property.value}</div>
                                    </div>
                                  </a>
                                ) : property.type === 'phone' ? (
                                  formatPhoneNumber(property.value)
                                ) : (
                                  property.value
                                )}
                              </td>
                            </tr>
                          ))}
                      {user
                        .filter(
                          (v) =>
                            v.name.startsWith('Штат') ||
                            v.alias.includes('fulltime') ||
                            (v.value && !v.uid.includes('booking_category'))
                        )
                        .map((property) => (
                          <tr key={property.uid}>
                            <td>{property.name}</td>
                            <td>
                              {isEmail(property.value) ? (
                                <a href={`mailto:${property.value}`}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginRight: 4,
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexShrink: 0
                                      }}
                                    >
                                      <EmailIcon />
                                    </div>
                                    <div> {property.value}</div>
                                  </div>
                                </a>
                              ) : property.type === 'phone' ? (
                                formatPhoneNumber(property.value)
                              ) : (
                                formatPropValue(property)
                              )}
                            </td>
                          </tr>
                        ))}
                    </Table>
                  </>
                )}
              </Container>
            </>
          )}
        </Wrapper>
      </Draggable>
    ) : (
      ''
    )
  )
}

export default SeatBar

const Name = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  margin-top: 12px;
`

const Container = styled.div<{ $expanded: boolean }>`
  margin: 1rem 1.6rem;
  max-height: 0;
  height: auto;
  transition: all 0.5s;
  overflow: hidden;

  ${Grid.Row} {
    padding: 1rem 0;
  }

  ${({ $expanded }) =>
    $expanded &&
    css`
      max-height: 500px;
    `}
`

const Title = styled.div`
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  color: #000000;
`

const Wrapper = styled(animated.div)`
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  max-height: 100%;
  max-width: 400px;
  min-height: 90px;
  width: 100%;
  z-index: 9101;
  overflow-y: auto;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
`

const SectionsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem 1rem 0 1rem;
`

const Section = styled.div<{ $active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-basis: 33.3%;
  cursor: pointer;
  position: relative;
  z-index: 10;

  &::before {
    content: ' ';
    position: absolute;
    border-radius: 50%;
    top: 0;
    width: 40px;
    height: 40px;
    background: #fff975;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s;
  }

  ${({ $active }) =>
    $active &&
    css`
      &::before {
        opacity: 1;
      }
    `}
  &:hover {
    &::before {
      opacity: 1;
    }
  }
`

const SectionTitle = styled.div<{ $expanded: boolean }>`
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: center;
  color: #000000;
  padding-top: 0.8rem;
  max-height: 0;
  height: auto;
  transition: all 0.5s;
  overflow: hidden;

  ${({ $expanded }) =>
    $expanded &&
    css`
      max-height: 30px;
    `}
`

const Table = styled.table`
  margin-top: 12px;

  td,
  th {
    text-align: left;
  }

  td {
    font-size: 12px;
    //width: 50%;
    vertical-align: middle;
    white-space: nowrap;
  }

  tr {
    padding: 1rem 0px;
  }

  tr td:first-child {
    padding: 4px 12px 4px 0;
    width: 30%;
    white-space: break-spaces;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  tr td:last-child {
    font-weight: 700;
    word-break: break-word;
    white-space: break-spaces;
    padding: 4px 0px 4px 4px;
  }
`

const formatProperty = ({ type = '', value }) => {
  if (type.includes('xml') || type.includes('html')) {
    return <span dangerouslySetInnerHTML={{ __html: value }} />
  }

  return formatBooleanValue(value)
}
